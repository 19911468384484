import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/close';
import { IconButton } from '@atlaskit/button/new';
import { JiraIcon } from '@atlaskit/logo';
import { token } from '@atlaskit/tokens';

import { SidePanelHeader } from '@confluence/side-panel';
import { fg } from '@confluence/feature-gating';

import { messages } from './messages';
import type { IssueCreateSidePanelHeaderProps } from './__types__/IssueCreateSidePanelHeader';

const headerMessageStyle = xcss({
	paddingInline: 'space.100',
	font: token('font.heading.small'),
	fontWeight: token('font.weight.medium'),
	paddingBottom: 'space.025',
});

export const IssueCreateSidePanelHeader = ({ onClose }: IssueCreateSidePanelHeaderProps) => {
	return (
		<SidePanelHeader>
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Heading size="medium" as="h2">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<Box>
							<JiraIcon size="small" appearance="brand" />
						</Box>
						<Box xcss={headerMessageStyle}>
							<FormattedMessage
								{...(fg('confluence-issue-terminology-refresh')
									? messages.sidePanelHeaderIssueTermRefresh
									: messages.sidePanelHeader)}
							/>
						</Box>
					</Flex>
				</Heading>
				<IconButton
					icon={() => <CrossIcon label="close-icon" spacing="none" />}
					appearance="subtle"
					label="close"
					onClick={() => onClose()}
					testId="close-issue-create-panel-button"
				/>
			</Flex>
		</SidePanelHeader>
	);
};
