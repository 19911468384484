import { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isFedRamp } from '@atlassian/atl-context';

import { fg } from '@confluence/feature-gating';
import { AccessStatus, ConfluenceEdition, useSessionData } from '@confluence/session-data';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';

import type { AiIssueCreationEnablementType } from '../providers/IssueCreateSidePanelContextProvider';
import {
	useAiIssueCreationEnablementState,
	useIssueCreateSidePanelContext,
} from '../providers/IssueCreateSidePanelContextProvider';
import { MESSAGE_PROJECT_TYPE_TO_API_PROJECT_TYPE } from '../__types__/apiUtils';

import { getJiraAiEnablement } from './apiUtils';

type UseIsAIIssueCreationAllowedProps = {
	contentId: string;
};

export const useIsAIIssueCreationAllowed = ({ contentId }: UseIsAIIssueCreationAllowedProps) => {
	const [
		{ currentSiteSelection, currentProjectSelection, isCreateManuallyPressed },
		{ setAiIssueCreationEnablement },
	] = useIssueCreateSidePanelContext();
	const { edition, accessStatus, cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isAIEnabledForContent } = useIsAIEnabledForContent({ contentId });
	const aiIssueCreationEnablement = useAiIssueCreationEnablementState();

	const hasLicensedConfluenceAccess =
		accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	const isConfluenceAiEnabled =
		edition === ConfluenceEdition.PREMIUM &&
		hasLicensedConfluenceAccess &&
		isAIEnabledForContent &&
		!isFedRamp();

	useEffect(() => {
		// isCreateManuallyPressed - When there is AI error "Create Manually" button can be pressed by user.
		// We should honour this and create manually.  This will be overriden on refresh.
		if (isCreateManuallyPressed) {
			void setAiIssueCreationEnablement({
				isEnabled: false,
			} as AiIssueCreationEnablementType);
			return;
		}

		// Short-circuit if Confluence Ai is enabled
		if (isConfluenceAiEnabled) {
			void setAiIssueCreationEnablement({
				isEnabled: true,
				enablement: 'confluence',
			} as AiIssueCreationEnablementType);
			return;
		}

		// Only update isEnabled AI state if not already set to false to avoid unnecessary state updates
		if (aiIssueCreationEnablement?.isEnabled && fg('confluence_ai_access_policy')) {
			void setAiIssueCreationEnablement({
				isEnabled: false,
				enablement: 'confluence',
			} as AiIssueCreationEnablementType);
		}

		if (!fg('jira_side_panel_aiwc_jira_ai_enablement')) {
			return;
		}

		const isJiraAiEnabled = async () => {
			/**
			 * 1. Cannot call Jira enablement API if site & project are not selected
			 * 2. Remove (cloudId !== currentSiteSelection) after Jira AI API supports
			 * 	  cross-site scenarios - https://hello.jira.atlassian.cloud/browse/CBT-4995
			 */
			if (
				!currentSiteSelection ||
				!currentProjectSelection?.selectedProjectType ||
				cloudId !== currentSiteSelection
			) {
				void setAiIssueCreationEnablement({
					isEnabled: false,
				} as AiIssueCreationEnablementType);
				return;
			}
			try {
				const projectType: string = currentProjectSelection.selectedProjectType;
				const jiraEnablement = await getJiraAiEnablement(
					currentSiteSelection,
					MESSAGE_PROJECT_TYPE_TO_API_PROJECT_TYPE[projectType],
				);

				// 	User switches from a Jira AI enabled site to a no AI enabled site
				if (aiIssueCreationEnablement?.enablement == 'jira' && !jiraEnablement?.isEnabled) {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							source: 'confluenceHighlightIssueCreate',
							action: 'disabled',
							actionSubject: 'issueCreationAi',
						},
					}).fire();
				}

				void setAiIssueCreationEnablement({
					isEnabled: jiraEnablement?.isEnabled ?? false,
					enablement: jiraEnablement?.isEnabled ? 'jira' : undefined,
				} as AiIssueCreationEnablementType);
			} catch (_error) {
				void setAiIssueCreationEnablement({
					isEnabled: false,
				} as AiIssueCreationEnablementType);
			}
		};
		void isJiraAiEnabled();
	}, [
		currentSiteSelection,
		currentProjectSelection?.selectedProjectType,
		setAiIssueCreationEnablement,
		aiIssueCreationEnablement,
		isConfluenceAiEnabled,
		createAnalyticsEvent,
		isCreateManuallyPressed,
		cloudId,
	]);
};
