import React, { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	useAiIssueCreationEnablementState,
	useIssueCreateSidePanelContext,
} from './providers/IssueCreateSidePanelContextProvider';
import { RainbowLoadingSidebar } from './RainbowLoadingSidebar';

type IssueCreateAiLoadingSidebarProps = {
	children: React.ReactNode;
};

export const IssueCreateAiLoadingSidebar = ({ children }: IssueCreateAiLoadingSidebarProps) => {
	const [{ aiState }] = useIssueCreateSidePanelContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const aiIssueCreationEnablement = useAiIssueCreationEnablementState();

	const isAiLoading = aiState === 'loading';
	const aiEnablement = aiIssueCreationEnablement?.enablement;

	useEffect(() => {
		if (isAiLoading) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'AI loading state',
					source: 'confluenceHighlightIssueCreate',
					attributes: {
						aiEnablement,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, isAiLoading, aiEnablement]);

	return <RainbowLoadingSidebar isShown={isAiLoading}>{children}</RainbowLoadingSidebar>;
};
