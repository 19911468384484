import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { doc, p } from '@atlaskit/adf-utils/builders';
import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives/compiled';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import type { DocNode } from '@atlaskit/adf-schema';

import { adfToMarkdownPlus, markdownPlusToAdf } from '@atlassian/adf-markdown-transformer';
import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';
import { ExperienceTrackerContext } from '@atlassian/experience-tracker';
import type { IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';
import { GicAnywhere } from '@atlassian/gic-anywhere';
import {
	type AiIssueCreateState,
	type AssistanceServiceMessageTemplateValues,
	type ContentType,
	type StateSource,
	ConfluenceAiIssueCreate as PlatformConfluenceAiIssueCreate,
	useAiIssueCreateExperienceTracker,
	useGetConfluencePage,
	useFetchAiSuggestedFields,
} from '@atlassian/ai-issue-create';
import { useAiWorkSuggestionStreaming } from '@atlassian/ai-work-suggestion';
import type { HookProps } from '@atlassian/ai-work-suggestion/types';
import type { ConvoAIStreamError } from '@atlassian/ai-agent-streaming';

import { componentWithFG } from '@confluence/feature-gate-component';
import { expVal } from '@confluence/feature-experiments';

import { countADFChars } from './utils/countADFChars';
import { useAiIssueCreateAnalytics } from './utils/useAiIssueCreateAnalytics';
import type { AiIssueCreationEnablementType } from './providers/IssueCreateSidePanelContextProvider';
import type { SuggestedIssuesErrorMessage } from './__types__/IssueCreateSidePanelBody';

export const DEFAULT_TIMEOUT = 7 * 1000; // 7s timeout, typical GIC load is ~3s

const style = cssMap({
	aiIssueCreateWrapper: { flexGrow: '1' },
});

type ConfluenceAiIssueCreateState = {
	state: AiIssueCreateState;
	error?: Error | unknown;
	message_template?: AssistanceServiceMessageTemplateValues;
	[key: string]: any;
};

type SuggestedFields = {
	summary?: string;
	description?: string;
};

type IDMap = {
	[key: string]: {
		type: string;
		attributes: any;
		marks?: any;
	};
};

export const useNonStreamingAssistanceService = ({
	cloudId,
	confluencePage,
	highlightedText,
	handleAiSuggestedFieldsStateChange,
	isReady,
	overrideXProduct,
}: {
	cloudId: string;
	confluencePage: string;
	highlightedText: string;
	handleAiSuggestedFieldsStateChange: (state: ConfluenceAiIssueCreateState) => void;
	isReady: boolean;
	overrideXProduct: string;
}): { state: 'loading' | AiIssueCreateState; fields: null | SuggestedFields } => {
	const result = useFetchAiSuggestedFields({
		cloudId,
		confluencePage,
		experienceId: 'ai-issue-create-confluence-single',
		highlightedText,
		product: 'confluence',
		onStateChange: handleAiSuggestedFieldsStateChange,
		overrideXProduct,
	});

	if (!isReady) {
		return {
			state: 'loading' as const,
			fields: null,
		};
	}

	return {
		state: result.state,
		fields: result.fields || null,
	};
};

export const useStreamingConvoAiService = ({
	confluencePage,
	highlightedText,
	isReady,
	overrideXProduct,
	handleAiSuggestedFieldsStateChange,
}: {
	confluencePage: string;
	highlightedText: string;
	isReady: boolean;
	overrideXProduct?: string;
	handleAiSuggestedFieldsStateChange: (state: ConfluenceAiIssueCreateState) => void;
}) => {
	const streamingHookProps: HookProps = useMemo(
		() => ({
			analyticsConfig: {
				aiFeatureName: 'confluenceAiWorkCreation',
				product: 'confluence',
				subproduct: 'jira',
				useDefaultGASv3EventSchema: false,
				source: 'highlightActionsMenu',
				skipAISessionValidation: true,
			},
			requestHeaderOverrides: {
				overrideXProduct,
			},
			issueConfig: {
				max_issues: 1,
				suggested_issue_field_types: [
					{
						issue_type: 'Task',
						fields: [
							{
								field_name: 'Summary',
								field_type: 'Short-Text',
							},
							{
								field_name: 'Description',
								field_type: 'Paragraph',
							},
						],
					},
				],
			},
			experienceId: 'ai-issue-create-confluence',
			sourceType: 'documentation',
			input: {
				source: 'CONFLUENCE',
				context: {
					primary_text: confluencePage,
					highlighted_text: highlightedText,
				},
			},
			onCompletion: () => {},
		}),
		[confluencePage, highlightedText, overrideXProduct],
	);

	const { suggestions, isStreaming, error } = useAiWorkSuggestionStreaming(streamingHookProps);

	// Transform streaming suggestions to match the expected fields format
	const transformedFields: SuggestedFields = useMemo(() => {
		// Only use suggestions when streaming is complete
		if (isStreaming) {
			return {
				summary: '',
				description: '',
			};
		}

		return {
			summary: suggestions?.[0]?.field_values?.Summary || '',
			description: suggestions?.[0]?.field_values?.Description || '',
		};
	}, [suggestions, isStreaming]);

	const getServiceState = () => {
		if (error) {
			return 'error';
		}
		if (isStreaming) {
			return 'loading';
		}
		return 'idle';
	};

	useEffect(() => {
		if (error) {
			handleAiSuggestedFieldsStateChange({
				state: 'error',
				error: convertConvoAIStreamErrorToSuggestedIssuesErrorMessage(error),
			});
		} else if (isStreaming) {
			handleAiSuggestedFieldsStateChange({
				state: 'loading',
				fields: transformedFields,
			});
		} else {
			handleAiSuggestedFieldsStateChange({
				state: 'idle',
				fields: transformedFields,
			});
		}
	}, [error, isStreaming, transformedFields, handleAiSuggestedFieldsStateChange]);

	if (!isReady) {
		return {
			state: 'loading' as const,
			fields: null,
		};
	}

	return {
		state: getServiceState(),
		fields: transformedFields,
	};
};

export const convertConvoAIStreamErrorToSuggestedIssuesErrorMessage = (
	error: ConvoAIStreamError,
): SuggestedIssuesErrorMessage => {
	return {
		message_template: error?.message?.message_template as AssistanceServiceMessageTemplateValues,
		statusCode: error?.message?.status_code,
		message: error?.message?.content,
		content: error?.message?.content,
	};
};

export type ConfluenceAiIssueCreateProps = {
	/**
	 * cloudId of the Jira instance
	 */
	cloudId: string;
	/**
	 * The consumer of AI Issue Create
	 * e.g. 'confluenceHighlight', 'rovoChat'
	 */
	consumer: string;
	/**
	 * The content type of the page - page or blog
	 */
	contentType: ContentType;
	/**
	 * Confluence page ID (aka contentId)
	 */
	pageId: string;
	/**
	 * The snippet of text that is highlighted in ADF format
	 */
	highlightedTextAdf: DocNode;
	/*
	 * overrideCloudId for site picker on GIC
	 */
	overrideCloudIdForSitePicker?: string;
	/*
	 * projectId for site picker on GIC
	 */
	overrideProjectIdForProjectPicker?: string;
	/**
	 * Test id for the component (optional)
	 */
	testId?: string;
	/**
	 * Time in ms for when iFrame should timeout (optional)
	 */
	timeoutMs?: number;
	/**
	 * Callback fired when Global issue create (GIC) is initialized
	 */
	onInit: () => void;
	/**
	 * Callback fired when issue create is successful
	 */
	onSuccess: (payload: IssueCreateEmbedSuccessPayload) => void;
	/**
	 * Callback fired when issue create fails
	 */
	onFailure: (
		error: Error | unknown,
		message_template?: AssistanceServiceMessageTemplateValues,
	) => void;
	/**
	 * Callback fired when issue create is closed
	 */
	onClose: (isCreateSuccess?: boolean) => void;
	/**
	 * Callback fired when state is clanged
	 */
	onStateChange: (state: AiIssueCreateState, source: StateSource) => void;
	/**
	 * Callback fired when issue create times out
	 */
	onTimeout?: () => void;
	/**
	 * Function to set the analytics context for issue create AI.
	 */
	setAiAnalyticsContext?: (key: string, value: number) => void;
	/**
	 * Ai enablement state
	 */
	aIIssueCreationEnablement?: AiIssueCreationEnablementType;
};

export const ConfluenceAiIssueCreateComponent = ({
	cloudId,
	overrideCloudIdForSitePicker,
	overrideProjectIdForProjectPicker,
	contentType,
	pageId,
	highlightedTextAdf,
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	testId,
	onStateChange,
	consumer,
	setAiAnalyticsContext,
	aIIssueCreationEnablement,
}: ConfluenceAiIssueCreateProps) => {
	const { fireTrack } = useAiIssueCreateAnalytics();
	const [confluencePage, setConfluencePage] = useState('');
	const [idMap, setIdMap] = useState({});
	const [isIdMapReady, setIsIdMapReady] = useState(false);

	// Custom handler to wrap multiple onStateChange calls
	const handleStateChange = useCallback(
		(source: StateSource, state: ConfluenceAiIssueCreateState) => {
			// trigger error if any state is errored
			if (onFailure && state?.state === 'error') {
				onFailure(state.error, state.message_template);
				fireTrack(`${source} failed`);
			}
			if (onStateChange) {
				onStateChange(state.state, source);
				fireTrack(`${source} updated`, { state: state.state });
			}
		},
		[onFailure, onStateChange, fireTrack],
	);

	const handleConfluencePageStateChange = useCallback(
		(newState: ConfluenceAiIssueCreateState) =>
			handleStateChange('aiIssueCreateGetConfluencePageState', newState),
		[handleStateChange],
	);

	// Fetch confluence page
	const { state: confluencePageState, confluencePage: confluencePageAsAdf } = useGetConfluencePage({
		cloudId,
		contentType,
		experienceId: 'ai-issue-create-confluence-single',
		pageId,
		onStateChange: handleConfluencePageStateChange,
	});

	// Effect to transform confluence page ADF to markdown after fetching
	useEffect(() => {
		if (confluencePageState === 'idle' && confluencePageAsAdf) {
			const { markdown, idMap: newIdMap } = adfToMarkdownPlus(confluencePageAsAdf);
			setAiAnalyticsContext &&
				setAiAnalyticsContext('pageContextLength', countADFChars(confluencePageAsAdf));
			setConfluencePage(markdown);
			setIdMap(newIdMap);
			setIsIdMapReady(true); // Set idMap ready when it's set
		}
	}, [confluencePageState, confluencePageAsAdf, setAiAnalyticsContext]);

	return (
		<Box xcss={style.aiIssueCreateWrapper} testId={testId ?? 'default-confluence-ai-issue-create'}>
			<UFOLoadHold
				name="ai-issue-create.adf-to-markdown-plus"
				hold={confluencePageState !== 'loading' && !isIdMapReady}
			/>
			<UFOLoadHold
				name="ai-issue-create.confluence-page-loading"
				hold={confluencePageState === 'loading'}
			/>

			{isIdMapReady ? (
				<WrappedConfluenceAiIssueCreateComponent
					handleStateChange={handleStateChange}
					confluencePageState={confluencePageState}
					confluencePage={confluencePage}
					idMap={idMap}
					useService={
						expVal('aiwc_migrate_to_convo_ai_from_assistance_service', 'isEnabled', false)
							? useStreamingConvoAiService
							: useNonStreamingAssistanceService
					}
					cloudId={cloudId}
					contentType={contentType}
					pageId={pageId}
					highlightedTextAdf={highlightedTextAdf}
					timeoutMs={timeoutMs}
					onInit={onInit}
					onSuccess={onSuccess}
					onFailure={onFailure}
					onClose={onClose}
					onTimeout={onTimeout}
					onStateChange={onStateChange}
					consumer={consumer}
					setAiAnalyticsContext={setAiAnalyticsContext}
					aIIssueCreationEnablement={aIIssueCreationEnablement}
					overrideCloudIdForSitePicker={overrideCloudIdForSitePicker}
					overrideProjectIdForProjectPicker={overrideProjectIdForProjectPicker}
				/>
			) : null}
		</Box>
	);
};

type ConfluenceAiIssueCreatePropsW = ConfluenceAiIssueCreateProps & {
	useService: any;
	handleStateChange: (source: StateSource, state: ConfluenceAiIssueCreateState) => void;
	confluencePageState: AiIssueCreateState;
	confluencePage: string;
	idMap: IDMap;
};

const WrappedConfluenceAiIssueCreateComponent = ({
	cloudId,
	overrideCloudIdForSitePicker,
	highlightedTextAdf = doc(p('')),
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	idMap,
	confluencePage,
	confluencePageState,
	handleStateChange,
	onFailure,
	onClose,
	onTimeout,
	consumer,
	setAiAnalyticsContext,
	useService,
	aIIssueCreationEnablement,
	overrideProjectIdForProjectPicker,
}: ConfluenceAiIssueCreatePropsW) => {
	const handleAiSuggestedFieldsStateChange = useCallback(
		(newState: ConfluenceAiIssueCreateState) =>
			handleStateChange('aiIssueCreateFetchSuggestedFieldsState', newState),
		[handleStateChange],
	);

	const highlightedText = useMemo(() => {
		return adfToMarkdownPlus(highlightedTextAdf, undefined, idMap).markdown;
	}, [highlightedTextAdf, idMap]);

	// Only call SuggestionComponent when highlightedText is ready
	const { state: suggestedFieldsState, fields } = useService({
		cloudId,
		confluencePage,
		highlightedText,
		handleAiSuggestedFieldsStateChange,
		isReady: highlightedText !== '',
		overrideXProduct: aIIssueCreationEnablement?.enablement,
	});

	const isLoading = confluencePageState === 'loading' || suggestedFieldsState === 'loading';
	const isErrored = confluencePageState === 'error' || suggestedFieldsState === 'error';
	const isPayloadReady = confluencePageState === 'idle' && suggestedFieldsState === 'idle';

	const descriptionADF = useMemo(() => {
		if (isLoading || isErrored || !fields?.description) {
			return doc(p(''));
		}
		return markdownPlusToAdf({ markdown: fields.description, idMap });
	}, [isLoading, isErrored, fields, idMap]);

	useEffect(() => {
		setAiAnalyticsContext &&
			setAiAnalyticsContext('aiResponseLength', countADFChars(descriptionADF));
	}, [descriptionADF, setAiAnalyticsContext]);

	useEffect(() => {
		setAiAnalyticsContext &&
			setAiAnalyticsContext('highlightedTextLength', countADFChars(highlightedTextAdf));
	}, [highlightedTextAdf, setAiAnalyticsContext]);

	return (
		<>
			<UFOLoadHold
				name="ai-issue-create.suggested-fields-loading"
				hold={suggestedFieldsState === 'loading'}
			/>
			{!isErrored && (
				<GicAnywhere
					cloudId={cloudId}
					summary={fields?.summary || ''}
					descriptionAdf={descriptionADF}
					timeoutMs={timeoutMs}
					onInit={onInit}
					onSuccess={onSuccess}
					onFailure={onFailure}
					onClose={onClose}
					onTimeout={onTimeout}
					consumer={consumer}
					isPayloadReady={isPayloadReady}
					overrideCloudId={overrideCloudIdForSitePicker}
					projectId={overrideProjectIdForProjectPicker}
				/>
			)}
		</>
	);
};
export const ConfluenceAiIssueCreateProduct = ({
	cloudId,
	contentType,
	pageId,
	overrideCloudIdForSitePicker,
	overrideProjectIdForProjectPicker,
	highlightedTextAdf = doc(p('')),
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	testId,
	onStateChange,
	consumer = 'confluenceHighlight',
	setAiAnalyticsContext,
	aIIssueCreationEnablement,
}: ConfluenceAiIssueCreateProps) => {
	const experienceTracker = useAiIssueCreateExperienceTracker();

	return (
		<AIEventsInstrumentationProvider
			config={{
				// Required configruation
				product: 'confluence',
				subproduct: 'jira',
				aiFeatureName: 'confluenceAiWorkCreation',
				proactiveGeneratedAI: 0,
				userGeneratedAI: 1,
				// Optional configuration
				source: 'confluenceHighlightIssueCreate',
				skipAISessionValidation: true,
			}}
		>
			<ExperienceTrackerContext.Provider value={experienceTracker}>
				<ConfluenceAiIssueCreateComponent
					cloudId={cloudId}
					contentType={contentType}
					pageId={pageId}
					highlightedTextAdf={highlightedTextAdf}
					timeoutMs={timeoutMs}
					onInit={onInit}
					onSuccess={onSuccess}
					onFailure={onFailure}
					onClose={onClose}
					onTimeout={onTimeout}
					testId={testId}
					onStateChange={onStateChange}
					consumer={consumer}
					overrideCloudIdForSitePicker={overrideCloudIdForSitePicker}
					overrideProjectIdForProjectPicker={overrideProjectIdForProjectPicker}
					setAiAnalyticsContext={setAiAnalyticsContext}
					aIIssueCreationEnablement={aIIssueCreationEnablement}
				/>
			</ExperienceTrackerContext.Provider>
		</AIEventsInstrumentationProvider>
	);
};

export const ConfluenceAiIssueCreate = componentWithFG<
	ConfluenceAiIssueCreateProps,
	ConfluenceAiIssueCreateProps
>(
	'confluence_jira_ai_issue_create_platform_to_connie',
	ConfluenceAiIssueCreateProduct,
	PlatformConfluenceAiIssueCreate,
);
